


















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    scale: {
      type: [Number, String],
      require: false,
      default: 1
    }
  }
})
